<template>
  <div>
    <div class="row mb-2">
      <div class="col-12">
        <h4 class="mb-3 text-center">Vessel’s Performance (Nbr Deficiency) </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-bordered w-100 text-center">
          <thead>
          <tr>
            <th rowspan="2">Vessel Name</th>
            <th rowspan="2">Vessel age</th>
            <th colspan="2" v-for="year in pscVesselPerformance.years">{{ year }}</th>
            <th colspan="3">Nbr Deficiency 5Y</th>
          </tr>
          <tr>
            <template v-for="year in pscVesselPerformance.years">
              <th class="rotate"><span>Nbr Inspection</span></th>
              <th class="rotate"><span>Nbr Deficiency</span></th>
            </template>
            <th class="rotate"><span>Nbr Inspection</span></th>
            <th class="rotate"><span>Nbr Deficiency</span></th>
            <th class="rotate"><span>Deficiency per Inspection</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="performance in pscVesselPerformance.data">
            <td>{{ performance.vessel_name }}</td>
            <td>{{ performance.age }}</td>

            <template v-for="year in pscVesselPerformance.years">
              <td>{{ performance[year]?.inspection_count ?? 0}}</td>
              <td>{{ performance[year]?.with_deficiency_count ?? 0 }}</td>
            </template>

            <td>{{ performance.total.inspection_count}}</td>
            <td>{{ performance.total.with_deficiency_count}}</td>
            <td>{{ performance.total.deficiency_per_inspection}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppLoading from "@/components/elements/AppLoading.vue";
import _ from "lodash"

export default {
  name: "PscVesselPerformance",
  components: {AppLoading},
  data(){
    return {
      loading:false,
      params:{
        year: null
      },
      years:Array.from({length:100},(_,i)=>new Date().getFullYear() - i)
    }
  },
  computed:{
    ...mapGetters(['pscVesselPerformance']),
  },
  methods:{
    ...mapActions(['getVesselPerformance']),
    async getData(){
      this.loading=true
      await this.getVesselPerformance(this.params)
      this.loading=false
    }
  },
  created(){
    this.getInspectionByCountry=_.debounce(this.getData,250)
    this.getData()
  },

}
</script>


<style scoped>
.table thead th {
  vertical-align: middle;
  border-bottom: 2px solid #dee2e6;
}

.rotate {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
</style>
